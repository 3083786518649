/*sí */

#modalRestorePassword>.modal-content {
    background-color: #FFC757;
    border-radius: 20px;
}

#modalRestorePassword>.modal-content>.modal-body {
    box-sizing: border-box;
    border-radius: 20px;
    background-color: #FFF;
    border: 3px solid #FFC757;
}

.container-login {
    max-width: 457px;
    border-radius: 15px;
    background-color: #F9F9F9;
    box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.83);
}

.font-login-logo {
    font-size: 30px;
    font-weight: 600;
    font-family: 'Quicksand';
    color: #FFF;
    margin: 0%;
}

.font-proveedores {
    color: #4F4F4F;
    font-family: 'Quicksand';
    font-size: 16px;
    font-weight: 600;
    margin: 1rem 0 0 0;
}

.form-group-login {
    display: flex;
    justify-content: center;
}

.group-button-login {
    font-family: 'Quicksand';
    margin: 0 0 1rem 0;
    display: flex;
    justify-content: flex-end;
}

.icon-requisicion {
    background-image: url('../img/requisicion.png');
    background-repeat: no-repeat;
}

.icon-pago {
    background-image: url('../img/pago.png');
    background-repeat: no-repeat;
}

.icon-proveedor {
    background-image: url('../img/proveedor.png');
    background-repeat: no-repeat;
}

.icon-cfdi {
    background-image: url('../img/cfdi.png');
    background-repeat: no-repeat;
}

.logo-proveedores {
    background-image: url('../img/Logo.png');
    background-repeat: no-repeat;
}

.modal-header-restore-password {
    border-color: transparent;
    padding-bottom: 0rem;
    font-size: 18px;
    color: #5B4B51;
}

.row-login-header {
    max-width: 457px;
    max-height: 61px;
    background-color: #1CBBC1;
    border-radius: 15px 15px 0 0;
    justify-content: center;
}

.row-login-form {
    padding: 0 1rem 0 1rem;
    justify-content: center;
}

/*sí */

.btn-login-g {
    background-color: transparent;
    border-color: #6b75ce;
    border-radius: 2rem;
    width: 250px;
}

.btn-login-google {
    display: inline-block;
    text-align: center;
    cursor: pointer;
    color: #6b75ce;
    padding: 0 0 0 25px;
    background-image: url(../img/google_icon-icons.com_62736.svg);
    background-repeat: no-repeat;
    background-size: 20px 20px;
    background-position: 0 2px;
}

.button-login {
    color: #1CBBC1;
    font-size: 18px;
    font-weight: 700;
    line-height: 10px;
    height: 30px;
    width: 110px;
    border-radius: 10px 10px 10px 10px;
    background-color: #ffffffc4;
    border-color: transparent;
}

.button-login:hover {
    color: #1CBBC1;
    font-size: 18px;
    font-weight: 700;
    line-height: 10px;
    height: 30px;
    width: 110px;
    border-radius: 10px 10px 10px 10px;
    background-color: #fff;
    border-color: transparent;
}

.b-login {
    text-align: end;
    margin-top: -30px;
    margin-left: -60px;
}

.iconLogin {
    background-color: transparent;
    border-color: transparent;
}