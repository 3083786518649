.btn-add-req {
    background-color: #6ee3af;
    border-color: #6ee3af;
    color: white;
}

.btn-add-req:hover {
    background-color: #6ee3af;
    border-color: #6ee3af;
    color: white;
}

.button-agregar-prov {
    background: #6ee3af;
    border-color: #6ee3af;
    color: #fff;
}

.button-agregar-prov:hover {
    background-color: #2aee97;
    border-color: #2aee97;
    color: #fff;
    cursor: pointer;
}

.button-editar-req {
    background: #eeaeca;
    border-color: #eeaeca;
    color: #fff;
}

.button-editar-req:hover {
    background-color: #e08bb0;
    border-color: #e08bb0;
    color: #fff;
    cursor: pointer;
}

.buttonAceptarConfirmar {
    background: #96caee;
    border-color: #96caee;
    color: #fff;
}

.buttonAceptarConfirmar:hover {
    background-color: #66c0ff;
    border-color: #66c0ff;
    color: #fff;
    cursor: pointer;
}

.buttonAceptarConfirmar:active {
    background-color: #66c0ff;
    border-color: #66c0ff;
    color: #fff;
    cursor: pointer;
}

.button-consultar-req {
    background-color: #e9b052;
    border-color: #e9b052;
    color: #fff;
    cursor: pointer;
}

.button-consultar-req:hover {
    background-color: #f27d24;
    border-color: #f27d24;
    color: #fff;
    cursor: pointer;
}

.button-eliminar-req {
    background: #cf9ee9;
    border-color: #cf9ee9;
    color: #fff;
}

.button-eliminar-req:hover {
    background-color: #c378eb;
    border-color: #c378eb;
    color: #fff;
    cursor: pointer;
}

.button-pedir-aprobacion {
    background: #6ee3af;
    border-color: #6ee3af;
    color: #fff;
}

.button-pedir-aprobacion:hover {
    background-color: #2aee97;
    border-color: #2aee97;
    color: #fff;
    cursor: pointer;
}

.buttonCancelar {
    background: #da6570;
    border-color: #da6570;
    color: #fff;
}

.buttonCancelar:hover {
    background-color: #e62f40;
    border-color: #e62f40;
    color: #fff;
    cursor: pointer;
}

.fieldset-editar-req {
    border: 1.5px solid #eeaeca !important;
    margin: 0;
    padding: 10px;
    position: relative;
    border-radius: 4px;
    background-color: #f5f5f580;
    padding-left: 10px!important;
}

.input-file-req {
    color: transparent;
}

.legend-crear-req {
    font-size: 18px;
    color: #6ee3af;
    text-align: left !important;
    font-weight: bold;
}
.legend-crear-req-not {
    font-size: 18px;
    color: #5B4B51;
    font-weight: bold;
}
.legend-share-req-modal {
    font-size: 18px;
    color: #5B4B51;
    font-weight: bold;
    text-align: center !important;
}
/*sí*/

@media(min-width: 320px) {
    #cardSeleccionarProveedor {
        border: 0rem;
        border-color: transparent;
    }
    #cardAprobMultiple {
        border: 0rem;
        border-color: transparent;
    }
}

label {
    display: inline-block;
    margin-bottom: 0rem;
}

#aprobarRequisiciones tbody tr td {
    border-top-color: transparent;
    border-top-style: solid;
    border-top-width: 1px;
}

#cotizConsulta tbody tr td {
    border-top-color: transparent;
    border-top-style: solid;
    border-top-width: 1px;
}

#cotizEditar tbody tr td {
    border-top-color: transparent;
    border-top-style: solid;
    border-top-width: 1px;
}

#selectBanco tbody tr td {
    border-top-color: transparent;
    border-top-style: solid;
    border-top-width: 1px;
}

#listaContactos tbody tr td {
    border-top-color: transparent;
    border-top-style: solid;
    border-top-width: 1px;
}

#modalConsultarRequisicion>.modal-content {
    background-color: #FFC757;
    border-radius: 20px;
}

#modalCrearRequisicion>.modal-content {
    background-color: #1CBBC1;
    border-radius: 20px;
}

#modalEditarRequisicion>.modal-content {
    background-color: #FD9558;
    border-radius: 20px;
}

#modalDeleteRequisicion>.modal-content {
    background-color: #FF5F58;
    border-radius: 20px;
}

#modalPedirAprobacion>.modal-content {
    background-color: #1CBBC1;
    border-radius: 20px;
}

#modalCancelarAprobacion>.modal-content {
    background-color: #1CBBC1;
    border-radius: 20px;
}

#modalConfirmarRequisicion>.modal-content {
    background-color: #4FD290;
    border-radius: 20px;
}

#modalDeleteCotizacion>.modal-content {
    background-color: #FF5F58;
    border-radius: 20px;
}

#modalDeleteRequisicion>.modal-content>.modal-body {
    box-sizing: border-box;
    border-radius: 20px;
    background-color: #FFF;
    border: 3px solid #FF5F58;
}

#modalPedirAprobacion>.modal-content>.modal-body {
    box-sizing: border-box;
    border-radius: 20px;
    background-color: #FFF;
    border: 3px solid #1CBBC1;
}

#modalCancelarAprobacion>.modal-content>.modal-body {
    box-sizing: border-box;
    border-radius: 20px;
    background-color: #FFF;
    border: 3px solid #1CBBC1;
}

#modalConfirmarRequisicion>.modal-content>.modal-body {
    box-sizing: border-box;
    border-radius: 20px;
    background-color: #FFF;
    border: 3px solid #4FD290;
}

#modalAprobarRequisiciones>.modal-content {
    background-color: #4FD290;
    border-radius: 20px;
}

#modalAprobarRequisiciones>.modal-content>.modal-body {
    box-sizing: border-box;
    border-radius: 20px;
    background-color: #FFF;
    border: 3px solid #4FD290;
}

#modalDeleteCotizacion>.modal-content>.modal-body {
    box-sizing: border-box;
    border-radius: 20px;
    background-color: #FFF;
    border: 3px solid #FF5F58;
}

.button-default-cotiz {
    border-radius: 10px;
    background-color: rgba(91, 75, 81, 0.8);
    border-color: rgba(91, 75, 81, 0.8);
    color: #FFF;
    font-weight: 600;
}

.cardAprobMultiple {
    padding: 0;
}

.div-crear-req {
    box-sizing: border-box;
    background-color: rgba(255, 255, 255, 0);
    border: 2px solid #5B4B51;
    font-weight: bolder;
    text-align: center;
}

.div-cotiz-consulta {
    box-sizing: border-box;
    background-color: rgba(255, 255, 255, 0);
    border: 2px solid #5B4B51;
}

.div-cotiz-editar {
    box-sizing: border-box;
    background-color: rgba(255, 255, 255, 0);
    border: 2px solid #5B4B51;
}

.div-select-banco {
    box-sizing: border-box;
    background-color: rgba(255, 255, 255, 0);
    border: 2px solid #5B4B51;
}

.modal-header-consultar-req {
    border-color: transparent;
    padding-bottom: 0rem;
    font-size: 18px;
    color: #5B4B51;
}

.modal-header-crear-req {
    border-color: transparent;
    padding-bottom: 0rem;
    font-size: 18px;
    color: #5B4B51;
}

.modal-header-editar-req {
    border-color: transparent;
    padding-bottom: 0rem;
    font-size: 18px;
    color: #5B4B51;
}

.modal-header-eliminar-req {
    border-color: transparent;
    padding-bottom: 0rem;
    font-size: 18px;
    color: #5B4B51;
}

.modal-header-pedir-aprobacion {
    border-color: transparent;
    padding-bottom: 0rem;
    font-size: 18px;
    color: #5B4B51;
}

.modal-header-cancelar-aprobacion {
    border-color: transparent;
    padding-bottom: 0rem;
    font-size: 18px;
    color: #5B4B51;
}

.modal-header-confirmar-req {
    border-color: transparent;
    padding-bottom: 0rem;
    font-size: 18px;
    color: #5B4B51;
}

.modal-header-eliminar-cotiz {
    border-color: transparent;
    padding-bottom: 0rem;
    font-size: 18px;
    color: #5B4B51;
}

.font-col-req {
    color: #FD9558;
    font-weight: bolder;
}

.id-req {
    color: #1CBBC1;
    font-weight: bolder;
}

.legend-consultar-req {
    font-size: 18px;
    color: #5B4B51;
    text-align: left !important;
    font-weight: 600;
    padding-left: 15px;
}
.legend-consultar-req-sm {
    font-size: 10px;
    color: #5B4B51;
    text-align: left !important;
    font-weight: 600;
    padding-left: 15px;
}

.legend-crear-req {
    font-size: 18px;
    color: #5B4B51;
    text-align: left !important;
    font-weight: 600;
    padding-left: 15px;
}

.legend-editar-req {
    font-size: 18px;
    color: #5B4B51;
    text-align: left !important;
    font-weight: 600;
    padding-left: 15px;
}

.req-tbody-tr-td-div {
    padding: .25rem .5rem;
}

.button-default-req {
    border-radius: 10px;
    background-color: rgba(91, 75, 81, 0.8);
    border-color: rgba(91, 75, 81, 0.8);
    color: #FFF;
    font-weight: 600;
}

.cardBodySeleccionarProveedor {
    padding: 0;
}

.form-control-font-size {
    font-size: 0.9rem;
}

.vencimientoMorado {
    background-color: #8f41d8;
    color: #fff;
}

.vencimientoNaranja {
    background-color: #f57226;
    color: #000;
}

.font-cancelar-requisicion {
    justify-content: space-around;
    font-weight: 400;
}

/*sí*/