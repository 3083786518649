@media(min-width:320px) {
    #cardCuentasEmisoras {
        border-color: transparent;
        border: 0rem;
    }
}

.fieldsetCuentasEmisoras {
    margin: 0;
    padding-top: 10px;
    border-radius: 4px;
}

.font-title-cuenta-emisora {
    padding: 0%;
    display: flex;
    justify-content: center;
    color: #fff;
    font-weight: bolder;
    font-size: 20px;
    border-radius: 20px 20px 0 0;
    background-color: #FD9558;
    width: 100%;
    height: 100%;
}

.cem-tbody-tr-td-div {
    padding: .25rem .25rem;
}

.font-col-cuenta-emisora {
    color: #000;
    font-weight: bolder;
}

.cardBodyCuentasEmisoras {
    padding: 0%;
}

#cuentaEmisora thead {
    display: flex;
    justify-content: flex-start;
}

#cuentaEmisora tbody {
    display: flex;
    justify-content: space-between;
}

#cuentaEmisora tbody tr {
    display: grid;
    margin: 1rem;
    border-radius: 20px;
    box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.83);
    /*min-width: 270px;*/
}

#cuentaEmisora tbody tr td {
    border-top-color: transparent;
    border-top-style: solid;
    border-top-width: 1px;
    padding: 0%;
}

.cardBodyCuentaEmisora {
    padding: 0%;
}

.fieldsetCuentaEmisora {
    margin: 0;
    padding-top: 10px;
    border-radius: 4px;
}

.font-title-cuenta-emisora {
    padding: 0%;
    display: flex;
    justify-content: center;
    color: #fff;
    font-weight: bolder;
    font-size: 20px;
    border-radius: 20px 20px 0 0;
    background-color: #FD9558;
    width: 100%;
    height: 100%;
}

.button-accion {
    background-color: transparent;
    border-color: transparent;
    color: #fff;
}

.legend-nueva-cuenta-emisora {
    font-size: 20px;
    color: #5B4B51;
    text-align: left !important;
    font-weight: 600;
    padding-left: 15px;
}

.fieldset-editar-cuenta-emisora {
    margin: 0;
    padding: 10px;
    position: relative;
    border-radius: 4px;
    background-color: #FFF;
    padding-left: 10px!important;
}

.legend-consultar-cuenta-emisora {
    font-size: 20px;
    color: #5B4B51;
    text-align: left !important;
    font-weight: 600;
    padding-left: 15px;
}

.legend-editar-cuenta-emisora {
    font-size: 20px;
    color: #5B4B51;
    text-align: left !important;
    font-weight: 600;
    padding-left: 15px;
}

.legend-eliminar-cuenta-emisora {
    font-size: 19px;
    color: #5B4B51;
    text-align: left !important;
    font-weight: 600;
    padding-left: 15px;
}

.form-cuenta {
    text-align: left !important;
}

.modal-header-nueva-cuenta-emisora {
    border-color: transparent;
    padding-bottom: 0rem;
    font-size: 18px;
    color: #5B4B51;
}

.modal-header-consultar-cuenta-emisora {
    border-color: transparent;
    padding-bottom: 0rem;
    font-size: 18px;
    color: #5B4B51;
}

.modal-header-editar-cuenta-emisora {
    border-color: transparent;
    padding-bottom: 0rem;
    font-size: 18px;
    color: #5B4B51;
}

.modal-header-eliminar-cuenta-emisora {
    border-color: transparent;
    padding-bottom: 0rem;
    font-size: 18px;
    color: #5B4B51;
}

.modal-body-eliminar-cuenta-emisora {
    background-color: #fff;
    color: #5B4B51;
    padding-bottom: 0rem;
}

.modal-body-cuenta-emisora {
    padding: 1rem 0 1rem 0;
}

#modalNuevaCuentaEmisora>.modal-content {
    background-color: #4FD290;
    border-radius: 20px;
}

#modalEditarCuentaEmisora>.modal-content {
    background-color: #F3CA3E;
    border-radius: 20px;
}

#modalConsultarCuentaEmisora>.modal-content {
    background-color: #1CBBC1;
    border-radius: 20px;
}

#modalDeleteCuentaEmisora>.modal-content {
    background-color: #FF5F58;
    border-radius: 20px;
}

#modalDeleteCuentaEmisora>.modal-content>.modal-body {
    box-sizing: border-box;
    border-radius: 20px;
    background-color: #FFF;
    border: 3px solid #FF5F58;
}