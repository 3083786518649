@import url('https://fonts.googleapis.com/css2?family=Quicksand');
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 0.9rem;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

@media(min-width:1400px) {
  #reqPendientes tbody tr {
    width: 380px;
  }
  #reqAprobadas tbody tr {
    width: 380px;
  }
  #reqPasadas tbody tr {
    width: 380px;
  }
  #pagosPendientes tbody tr {
    width: 380px;
  }
  #pagosPasados tbody tr {
    width: 380px;
  }
  #cfdiPendientes tbody tr {
    width: 380px;
  }
  #cfdiPasados tbody tr {
    width: 380px;
  }
  #ventasProveedores tbody tr {
    width: 380px;
  }
  #nuevosProveedores tbody tr {
    width: 380px;
  }
  .modal-portal-body {
    padding: 1rem 0 0 0;
  }
}

@media (min-width: 568px) {
  #logoProveedores {
    font-size: 80px;
  }
}

@media (min-width: 320px) {
  #containerLogin, #sidenavmenu {
    font-family: 'Quicksand';
  }
}

nav a {
  color: #1CBBC1
}

nav a:hover {
  color: #1CBBC1
}

nav span:hover {
  color: #1CBBC1
}

.button-default-black {
  background-color: transparent;
  border-color: transparent;
  color: #fff;
}

.button-default-black:hover {
  background-color: #7a7a7a;
  border-color: #7a7a7a;
  color: #fff;
  fill: #fff;
}

.button-default-black-transparent {
  background-color: transparent;
  border-color: transparent;
  color: #000;
}

.button-default-black-transparent:hover {
  background-color: transparent;
  border-color: transparent;
  color: #FFF;
  fill: #fff;
}

.button-default-subir-archivo {
  border-radius: 10px;
  background-color: rgba(91, 75, 81, 0.8);
  border-color: rgba(91, 75, 81, 0.8);
  color: #FFF;
  font-weight: 600;
}

.button-default-subir-archivo-disabled {
  border-radius: 10px;
  background-color: rgba(199, 195, 197, 0.8);
  border-color: rgba(199, 195, 197, 0.8);
  color: #FFF;
  font-weight: 600;
}

/*modals default*/

.button-default-cancelar {
  border-radius: 10px;
  background-color: #F3CA3E;
  border-color: #F3CA3E;
  color: #FFF;
  font-weight: 600;
}

.button-default-confirmar {
  border-radius: 10px;
  background-color: #4FD290;
  border-color: #4FD290;
  color: #FFF;
  font-weight: 600;
}



.container-modals {
  padding-right: 0px;
  padding-left: 0px;
}

.form-label {
  margin-bottom: 0px;
}

.form-group {
  margin-bottom: 0.5rem;
}

.form-control {
  color: #5B4B51;
  font-weight: 600;
  opacity: 1;
  background-color: #FFF;
  border: 1px solid #5B4B51;
}

.form-control:disabled, .form-control[readonly] {
  opacity: 1;
  background-color: #f5f5f580;
  border: 1px solid #5B4B51;
}

.form-control:focus {
  color: #5B4B51;
  background-color: #fff;
  border-color: #5B4B51;
  outline: 0;
  box-shadow: 0 0 0 .1rem #5B4B51;
}

.form-row {
  padding-right: 0px;
}

.input-signs-right {
  border-radius: 0 5px 5px 0;
  background-color: #5B4B51;
  color: #FFF;
  border: 1px solid #5B4B51;
}

.input-signs-left {
  border-radius: 5px 0 0 5px;
  background-color: #5B4B51;
  color: #FFF;
  border: 1px solid #5B4B51;
}

.input-group>.input-group-prepend>.input-group-text {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-signs-align {
  text-align: right;
}

.modal {
  z-index: 1201;
  font-family: 'Quicksand';
  font-size: .875rem;
  font-weight: 600;
}

.modal-footer {
  border-top: 1px solid transparent;
}

/*modals default*/

/*tablas de requisiciones*/

.table thead th {
  border-bottom: 0 none transparent;
}

.table th {
  border-top: 0 none transparent;
}

/*tablas de requisiciones*/

/*Inicio diseño del paginador */

.page-item.active .page-link {
  z-index: 1;
  color: #fff;
  background-color: #5B4B51;
  border-color: #5B4B51;
}

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 0.95;
  color: #5B4B51;
  background-color: #fff0;
  border: 1px solid #fff0;
}

.page-link:focus {
  z-index: 2;
  outline: 0;
  box-shadow: 0 0 0.2rem rgba(244, 173, 101, 0)
}

.page-link:hover {
  z-index: 2;
  color: #000000;
  text-decoration: none;
  background-color: #e9ecef00;
  border-color: #dee2e600;
}

/*Fin diseño del paginador */