@media(min-width:320px) {
    #cardProvCatalogo {
        border-color: transparent;
        border: 0rem;
    }
}

.button-editar-accion {
    background-color: transparent;
    border-color: transparent;
    color: #fff;
}

.button-eliminar-accion {
    background-color: transparent;
    border-color: transparent;
    color: #fff;
}

.fieldset-crear-prov {
    border: 1.5px solid #6ee3af !important;
    margin: 0;
    padding: 10px;
    position: relative;
    border-radius: 4px;
    background-color: #f5f5f580;
    padding-left: 10px!important;
}

.legend-crear-prov {
    font-size: 20px;
    color: #5B4B51;
    text-align: left !important;
    font-weight: 600;
    padding-left: 15px;
}

.legend-crear-prov-form {
    font-size: 20px;
    color: #5B4B51;
    text-align: left !important;
    font-weight: 600;
    padding-left: 15px;
    background-color: #dee2e6;
}

.fieldset-editar-prov {
    margin: 0;
    padding: 0px;
    position: relative;
    border-radius: 20px;
    background-color: #fff;
    padding-left: 10px!important;
}

.legend-editar-prov {
    font-size: 20px;
    color: #5B4B51;
    text-align: left !important;
    font-weight: 600;
    padding-left: 15px;
    background-color: rgba(255, 255, 255, 0);
}

.fieldset-consultar-prov-info {
    margin: 0;
    padding: 0px;
    position: relative;
    border-radius: 20px;
    padding-left: 0px!important;
    padding-right: 10px;
}

.fieldset-consultar-prov-direccion {
    margin: 0;
    padding: 0px;
    position: relative;
    border-radius: 20px;
    padding-left: 10px!important;
    padding-right: 0px;
}

.legend-consultar-prov {
    font-size: 20px;
    color: #5B4B51;
    text-align: left !important;
    font-weight: 600;
    padding-left: 15px;
}

.form-proveedor {
    text-align: left !important;
}

.modal-header-crear-prov {
    border-color: transparent;
    padding-bottom: 0rem;
    font-size: 18px;
    color: #5B4B51;
}

.modal-prov {
    height: 464px;
    width: 383px;
    border-radius: 20px;
    background-color: #4FD290;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
}

.modal-header-consultar-prov {
    border-color: transparent;
    padding-bottom: 0rem;
    font-size: 18px;
    color: #5B4B51;
}

.modal-header-editar-prov {
    border-color: transparent;
    padding-bottom: 0rem;
    font-size: 18px;
    color: #5B4B51;
}

.modal-header-eliminar-prov {
    border-color: transparent;
    padding-bottom: 0rem;
    font-size: 18px;
    color: #5B4B51;
}

.modal-body-proveedor {
    padding: 1rem 0 1rem 0;
}

.card-direcciones {
    padding: 0;
}

.fieldsetProveedores {
    margin: 0;
    padding-top: 10px;
    border-radius: 4px;
}

.prov-tbody-tr-td-div {
    padding: 0rem 1rem;
}

.font-title-proveedores {
    padding: 0%;
    display: flex;
    justify-content: center;
    color: #fff;
    font-weight: bolder;
    font-size: 20px;
    border-radius: 20px 20px 0 0;
    background-color: #FD9558;
    width: 100%;
    height: 100%;
}

.font-rowOne-proveedores {
    display: flex;
    justify-content: space-around;
    background-color: rgba(91, 75, 81, 0.3);
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.83);
    border-radius: 20px;
}

.font-col-proveedores {
    color: #000;
    font-weight: bolder;
    justify-content: center;
    text-align: center;
}

.font-info-proveedores {
    color: #000;
    justify-content: center;
    text-align: center;
}

.cardBodyProveedores {
    padding: 0%;
}

#proveedores thead {
    display: flex;
    justify-content: flex-start;
}

#proveedores tbody {
    display: flex;
    justify-content: space-between;
}

#proveedores tbody tr {
    display: grid;
    margin: 1rem;
    border-radius: 20px;
    box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.83);
    /*min-width: 270px;*/
}

#proveedores tbody tr td {
    border-top-color: transparent;
    border-top-style: solid;
    border-top-width: 1px;
    padding: 0%;
}

#modalCrearProveedor>.modal-content {
    background-color: #4FD290;
    border-radius: 20px;
}

#modalEditarProveedor>.modal-content {
    background-color: #FD9558;
    border-radius: 20px;
}

#modalConsultarProveedor>.modal-content {
    background-color: #1CBBC1;
    border-radius: 20px;
}

#modalDeleteProveedor>.modal-content {
    background-color: #FF5F58;
    border-radius: 20px;
}

#modalDeleteProveedor>.modal-content>.modal-body {
    box-sizing: border-box;
    border-radius: 20px;
    background-color: #FFF;
    border: 3px solid #FF5F58;
}