@media(min-width: 320px) {
    #cardPagosPasados {
        border: 0rem;
        border-color: transparent;
    }
}

#pagosPasados thead {
    display: flex;
    justify-content: flex-start;
}

#pagosPasados tbody {
    display: flex;
    justify-content: space-between;
}

#pagosPasados tbody tr {
    display: grid;
    margin: 1rem;
    border-radius: 20px;
    box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.83);
    /*min-width: 270px;*/
}

#pagosPasados tbody tr td {
    border-top-color: transparent;
    border-top-style: solid;
    border-top-width: 1px;
    padding: 0%;
}

.cardBodyPagosPasados {
    padding: 0%;
}

.fieldsetPagosPasados {
    margin: 0;
    padding-top: 10px;
    border-radius: 4px;
}

.font-title-pagos-pasados {
    padding: 0%;
    display: flex;
    justify-content: center;
    color: #fff;
    font-weight: bolder;
    font-size: 20px;
    border-radius: 20px 20px 0 0;
    background-color: #4FD290;
    width: 100%;
    height: 100%;
}

.font-footer-pagos-pasados {
    padding: 0%;
    display: flex;
    justify-content: center;
    color: #fff;
    font-weight: bolder;
    font-size: 20px;
    border-radius: 0 0 20px 20px;
    background-color: #4FD290;
    width: 100%;
    height: 100%;
}

.font-rowTwo-pagos-pasados {
    display: flex;
    justify-content: space-around;
    background-color: rgba(91, 75, 81, 0.3);
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.83);
    border-radius: 20px 20px 0 0;
}

.button-refresh-filter {
    height: 25px;
    width: 85px;
    border-radius: 10px 10px 10px 10px;
    border-color: #4FD290;
    background-color: #4FD290;
    font-size: 14px;
    font-weight: 700;
    line-height: 10px;
    color: #FFF;
}

.button-mes-actual-filter {
    height: 27px;
    width: 85px;
    border-radius: 5px;
    border-color: #FFC757;
    background-color: #FFC757;
    font-size: 13px;
    font-weight: 700;
    line-height: 10px;
    color: #5B4B51;
}

.button-ultimos-dias-filter {
    height: 27px;
    width: 100px;
    border-radius: 5px;
    border-color: #FFC757;
    background-color: #FFC757;
    font-size: 13px;
    font-weight: 700;
    line-height: 10px;
    color: #5B4B51;
}

.button-mes-anterior-filter {
    height: 27px;
    width: 90px;
    border-radius: 5px;
    border-color: #FFC757;
    background-color: #FFC757;
    font-size: 13px;
    font-weight: 700;
    line-height: 9px;
    color: #5B4B51;
}

.button-year-filter {
    height: 27px;
    width: 85px;
    border-radius: 5px;
    border-color: #FFC757;
    background-color: #FFC757;
    font-size: 13px;
    font-weight: 700;
    line-height: 10px;
    color: #5B4B51;
}

.date-filter {
    justify-content: center;
}