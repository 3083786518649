#modalHeaderDeudas>.card-header:first-child {
  border-radius: 20px 20px 0 0 important!;
}

/* DASHBOARD */

@media(min-width:320px) {
  #dashboard {
    border-color: transparent;
    border: 0rem;
  }
}

/* ---------------------------------- */

/* DEUDAS ANTIGUAS */

#ventasProveedores thead {
  display: flex;
  justify-content: flex-start;
}

#ventasProveedores tbody {
  display: flex;
  justify-content: space-between;
}

#ventasProveedores tbody tr {
  display: grid;
  margin: 1rem;
  border-radius: 20px;
  box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.83);
  min-width: 175px;
}

#ventasProveedores tbody tr td {
  border-top-color: transparent;
  border-top-style: solid;
  border-top-width: 1px;
  padding: 0%;
}

.cardProveedoresVentas {
  /*width: 700px;*/
  border: 0px;
  float: left;
  margin: 0.5rem 1rem 0rem 1rem;
}

.fieldsetProvedorVentas {
  margin: 0;
  padding-top: 10px;
  border-radius: 4px;
}

.prov-ventas-tbody-tr-td-div {
  padding: .25rem .25rem;
  justify-content: flex-start important!;
}

.prov-ventas {
  padding: .25rem .25rem;
  height: 21px;
  color: #333;
  font-size: 16px;
  font-weight: 300;
  line-height: 22px;
}

.title-prov-ventas {
  color: #FD9558;
  font-weight: bolder;
  font-size: 20px;
  justify-content: center;
  text-align: center;
}

.font-col-prov-ventas {
  color: #000;
  font-weight: bolder;
}

.font-title-proveedor-ventas {
  padding: 0%;
  display: flex;
  justify-content: center;
  color: #fff;
  font-weight: bolder;
  font-size: 20px;
  border-radius: 20px 20px 0 0;
  background-color: #FD9558;
  width: 100%;
  height: 100%;
}

.proveedores-con-mas-ventas-tit {
  height: 45px;
  /* width: 100%;*/
  background-color: #4FD290;
  border-radius: 20px 20px 0 0;
  color: #fff;
  font-weight: bolder;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
}

.prov-ventas {
  padding: .25rem .25rem;
  height: 21px;
  color: #333;
  font-size: 16px;
  font-weight: 300;
  line-height: 22px;
}

/*--------------------------*/

/* DEUDAS ANTIGUAS */

#deudasAntiguas thead {
  display: flex;
  justify-content: flex-start;
}

#deudasAntiguas tbody {
  display: flex;
  justify-content: space-between;
}

#deudasAntiguas tbody tr {
  display: grid;
  margin: 1rem;
  border-radius: 20px;
  box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.83);
  /*min-width: 270px;*/
}

#deudasAntiguas tbody tr td {
  border-top-color: transparent;
  border-top-style: solid;
  border-top-width: 1px;
  padding: 0%;
}

.deudas-mas-viejas-titulo {
  height: 45px;
  /*width: 310px;*/
  border-radius: 20px 20px 0 0;
  background-color: #1CBBC1;
  color: #FFF;
  font-weight: bolder;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
}

.deudas-viejas-tbody-tr-td-div {
  padding: .25rem .25rem;
}

.deudas-viejas {
  padding: 0.25rem 0.25rem;
  height: 45px;
  width: 205px;
  color: #333;
  font-size: 18px;
  font-weight: 300;
  line-height: 55px;
  align-content: center;
  font-weight: bolder;
}

.title-empresa-deuda {
  color: #1CBBC1;
  font-weight: bolder;
  font-size: 30px;
  justify-content: center;
  text-align: center;
}

.cardDeudasViejas {
  border: 0px;
  float: right;
  /*margin: 0.5rem 1rem 1rem 0.5rem;*/
}

#deudasAntiguas tbody {
  align-items: center;
  justify-content: center;
}

/*--------------------------*/

/* NUEVOS PROVEEDORES */

#nuevosProveedores thead {
  display: flex;
  justify-content: space-between;
}

#nuevosProveedores tbody {
  display: flex;
  justify-content: space-between;
}

#nuevosProveedores tbody tr {
  display: grid;
  margin: 1rem;
  border-radius: 20px;
  box-shadow: 0 4px 6px 0 #000000d4;
}

#nuevosProveedores tbody tr td {
  border-top-color: transparent;
  border-top-style: solid;
  border-top-width: 1px;
  font-size: 13px;
  text-align: webkit-right;
  padding: 0%;
}

.cardNuevosProveedores {
  height: 350px;
  /*width: 700px;
  margin: 0.5rem 1rem 1rem 0.5rem;*/
  border: 0px;
  /*float: left;*/
}

.nuevos-proveedores-tit {
  height: 45px;
  /*width: 700px;*/
  border-radius: 20px 20px 0 0;
  /*background-color: #4FD290;*/
  background-color: #5B4B51;
  font-size: 20px;
  font-weight: 600;
  line-height: 20px;
  color: #FFF;
  justify-content: center;
  align-items: center;
  display: flex;
}

.font-title-nuevo-proveedor {
  padding: 0%;
  display: flex;
  justify-content: center;
  color: #fff;
  font-weight: bolder;
  font-size: 20px;
  border-radius: 20px 20px 0 0;
  background-color: #FD9558;
  width: 100%;
  height: 100%;
}

.font-col-nuevo-prov {
  color: #FD9558;
  font-weight: bolder;
  text-align: left;
}

.nuevo-prov-tbody-tr-td-div {
  padding: .20rem .20rem;
}

/* -------------------------------- */

/* CALENDARIO */

.headerCalendario {
  border-radius: 20px 20px 0 0 important!;
  background-color: #FFC757;
  margin: 0.5rem 0rem 0rem 0rem;
  Width: 285px;
  Height: 45px;
  justify-content: center;
  align-items: center;
  display: flex;
  font-size: 20px;
  font-weight: 600;
  line-height: 10px;
}

.cardCalendario {
  height: 240px;
  width: 285px;
  border: 0px;
  float: right;
  top: 80px;
}

.calendario {
  height: 240px;
  width: 285px;
}

.calendario {
  height: 240px;
  width: 285px;
}

/* --------------------------- */

.cardSaldosLiquidar {
  height: 296px;
  width: 690px;
  border: 0px;
  float: left;
}

.saldos-a-liquidar-header {
  height: 45px;
  /*width: 690px;*/
  border-radius: 20px 20px 0 0;
  background-color: #5B4B51;
  color: #fff;
  justify-content: center;
  align-items: center;
  display: flex;
  font-size: 20px;
  font-weight: 600;
  line-height: 20px;
}

.saldos-a-liquidar {
  height: 296px;
  width: 719px;
  border-radius: 20px, 20px 0 0;
  color: #fff;
  font-weight: bolder;
  margin: 0.5rem 2rem 1rem 2rem;
  justify-content: center;
  align-items: center;
  display: flex;
}

.saldosSeleccionar {
  height: 45px;
  width: 719px;
  border-radius: 20px 20px 0 0;
  margin: 0.5rem 2rem 1rem 2rem;
  background-color: #FFC757;
}

.saldos-a-liquidar {
  height: 296px;
  width: 719px;
  border-radius: 20px, 20px 0 0;
  color: #fff;
  font-weight: bolder;
  margin: 0.5rem 2rem 1rem 2rem;
  justify-content: center;
  align-items: center;
  display: flex;
}

.saldosSeleccionar {
  height: 45px;
  width: 719px;
  border-radius: 20px 20px 0 0;
  margin: 0.5rem 2rem 1rem 2rem;
  background-color: #FFC757;
}

.texto-grafica {
  font-size: 20px;
  justify-content: center;
}

/* ---------------------------- */

/*.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active{*/
.tabDashboard .nav-link.active {
  color: #495057;
  background-color: #FFC757;
  border-color: #dee2e6 #dee2e6 #fff;
}

nav a:hover{
  color: #444e4e;
}

nav a{
  color: #444e4e;
}

.bg-preload {
  position: relative;
  background-color:#F4F6F6;
  overflow: hidden;
}

.content-box {
  margin: 5px 5px 0px 0px;
  padding: 10vh 0px;
}

.load-skeleton {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 50%;
  z-index: 1;
  width: 500%;
  margin-left: -250%;
  -webkit-animation: bgAnimation 0.8s linear infinite;
  animation: bgAnimation 0.8s linear infinite;
  background: linear-gradient(
      90deg,
      hsla(0, 0%, 100%, 0) 46%,
      hsla(0, 0%, 100%, 0.35) 50%,
      hsla(0, 0%, 100%, 0) 54%
    )
    50% 50%;
}

@keyframes bgAnimation {
  0% {
    transform: translate3d(-30%, 0, 0);
  }
  to {
    transform: translate3d(30%, 0, 0);
  }
}