@media(min-width: 320px) {
    #cardEmpresa, #cardCambiarContrasena {
        border: 0rem;
        border-color: transparent;
    }
    #cardCuentasNotificacion{
        border: 0rem;
        border-color: transparent;
    }

}

#empresasDisponibles tbody tr td {
    border-top-color: transparent;
    border-top-style: solid;
    border-top-width: 1px;
}

#listaUsuarios tbody tr td {
    border-top-color: transparent;
    border-top-style: solid;
    border-top-width: 1px;
}

#listaContactos tbody tr td {
    border-top-color: transparent;
    border-top-style: solid;
    border-top-width: 1px;
}

#modalAgregarUsuario>.modal-content {
    background-color: #1CBBC1;
    border-radius: 20px;
}

#modalAgregarUsuario>.modal-content>.modal-body {
    box-sizing: border-box;
    border-radius: 20px;
    background-color: #FFF;
    border: 3px solid #1CBBC1;
}

#modalAgregarEmpresaUsuario>.modal-content {
    background-color: #4FD290;
    border-radius: 20px;
}

#modalAgregarUsuarioContacto>.modal-content {
    background-color: #4FD290;
    border-radius: 20px;
}

#modalEditarEmpresaUsuario>.modal-content {
    background-color: #FD9558;
    border-radius: 20px;
}

#modalEditarUsuarioContacto>.modal-content {
    background-color: #FD9558;
    border-radius: 20px;
}

#modalUpdateEstatusUsuario>.modal-content {
    background-color: #FD9558;
    border-radius: 20px;
}

#modalUpdateEstatusUsuario>.modal-content>.modal-body {
    box-sizing: border-box;
    border-radius: 20px;
    background-color: #FFF;
    border: 3px solid #FD9558;
}

#modalEliminarUsuario>.modal-content {
    background-color: #FF5F58;
    border-radius: 20px;
}

#modalEliminarUsuario>.modal-content>.modal-body {
    box-sizing: border-box;
    border-radius: 20px;
    background-color: #FFF;
    border: 3px solid #FF5F58;
}

#modalEliminarContacto>.modal-content {
    background-color: #FF5F58;
    border-radius: 20px;
}

#modalEliminarContacto>.modal-content>.modal-body {
    box-sizing: border-box;
    border-radius: 20px;
    background-color: #FFF;
    border: 3px solid #FF5F58;
}

#modalUsuarioContactos>.modal-content {
    background-color: #FFC757;
    border-radius: 20px;
}

#modalUsuarioContactos>.modal-content>.modal-body {
    box-sizing: border-box;
    border-radius: 20px;
    background-color: #FFF;
    border: 3px solid #FFC757;
}

.cardBodyEmpresa {
    padding: 0;
}

.cardBodyCuentasNotificacion {
    padding: 0%;
}

.header-class-configuracion {
    border-color: #1CBBC1;
    border-top-style: solid;
    border-bottom-style: solid;
}

.legend-empresa-usuario {
    font-size: 18px;
    color: #5B4B51;
    text-align: left !important;
    font-weight: 600;
}

.modal-header-agregar-usuario {
    border-color: transparent;
    padding-bottom: 0rem;
    font-size: 18px;
    color: #5B4B51;
}

.modal-header-empresa-usuario {
    border-color: transparent;
    padding-bottom: 0rem;
    font-size: 18px;
    color: #5B4B51;
}