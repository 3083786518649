@media(min-width: 320px) {
    #cardCfdiPendientes {
        border: 0rem;
        border-color: transparent;
    }
}

#cfdiPendientes thead {
    display: flex;
    justify-content: flex-start;
}

#cfdiPendientes tbody {
    display: flex;
    justify-content: space-between;
}

#cfdiPendientes tbody tr {
    display: grid;
    margin: 1rem;
    border-radius: 20px;
    box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.83);
}

#cfdiPendientes tbody tr td {
    border-top-color: transparent;
    border-top-style: solid;
    border-top-width: 1px;
    padding: 0%;
}

.cardBodyCfdiPendientes {
    padding: 0%;
}

.fieldsetCfdiPendientes {
    margin: 0;
    padding-top: 10px;
    border-radius: 4px;
}

.font-title-cfdi-pendientes {
    padding: 0%;
    display: flex;
    justify-content: center;
    color: #fff;
    font-weight: bolder;
    font-size: 20px;
    border-radius: 20px 20px 0 0;
    background-color: #FD9558;
    width: 100%;
    height: 100%;
}

.font-rowTwo-cfdi-pendientes {
    display: flex;
    justify-content: space-around;
    background-color: rgba(91, 75, 81, 0.3);
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.83);
    border-radius: 20px;
}