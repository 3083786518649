#cardTitleContent h5 {
    border-radius: 20px 20px 0 0;
    background-color: #5B4B51;
    color: #fff;
    font-weight: bolder;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 45px;
    margin: 0.5rem 2rem 1rem 2rem;
}

.div-profile {
    height: 174px;
    width: 174px;
    background-color: #FFC757;
    box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.83);
}

.div-perfil-two {
    height: 78px;
}

.font-profile-one {
    color: #333;
    font-size: 20px;
    font-weight: 600;
    margin: 0%;
}

.font-profile-two {
    color: #333;
    font-size: 16px;
    font-weight: 600;
    margin: 0%;
}

.icon-profile {
    background-image: url('../img/perfil.png');
    background-repeat: no-repeat;
}

.icon-requisicion-menu {
    background-image: url('../img/iconRequisicion.png');
    background-repeat: no-repeat;
}

.icon-pago-menu {
    background-image: url('../img/iconPago.png');
    background-repeat: no-repeat;
}

.icon-proveedor-menu {
    background-image: url('../img/iconProveedor.png');
    background-repeat: no-repeat;
}

.icon-cfdi-menu {
    background-image: url('../img/iconCfdi.png');
    background-repeat: no-repeat;
}

.icon-soporte-menu {
    background-image: url('../img/iconSoporte.png');
    background-repeat: no-repeat;
}

.icon-nueva-req {
    background-image: url('../img/iconNewReq.png');
    background-repeat: no-repeat;
}

.icon-autorizar-req {
    background-image: url('../img/iconAutorizarReq.png');
    background-repeat: no-repeat;
}

.icon-atorizacion-masiva {
    background-image: url('../img/iconAutorizacionMasiva.png');
    background-repeat: no-repeat;
}

.icon-aprobadas {
    background-image: url('../img/iconAprobadas.png');
    background-repeat: no-repeat;
}

.icon-pagos {
    background-image: url('../img/iconPagos.png');
    background-repeat: no-repeat;
}

.icon-pago-pendiente {
    background-image: url('../img/iconPagoPendiente.png');
    background-repeat: no-repeat;
}

.icon-pago-multiple {
    background-image: url('../img/iconPagoMultiple.png');
    background-repeat: no-repeat;
}

.icon-pagados {
    background-image: url('../img/iconPagados.png');
    background-repeat: no-repeat;
}
