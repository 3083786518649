.button-eliminar-cuenta {
    height: 25px;
    width: 107px;
    border-radius: 10px 10px 10px 10px;
    background-color: #4FD290;
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
    color: #FFF;
}

.legend-agregar-cuenta {
    font-size: 20px;
    color: #5B4B51;
    text-align: left !important;
    font-weight: 600;
    padding-left: 15px;
}

.fieldset-editar-cuenta {
    margin: 0;
    padding: 10px;
    position: relative;
    border-radius: 4px;
    background-color: #FFF;
    padding-left: 10px!important;
}

.legend-editar-cuenta {
    font-size: 20px;
    color: #5B4B51;
    text-align: left !important;
    font-weight: 600;
    padding-left: 15px;
}

.legend-eliminar-cuenta {
    font-size: 19px;
    color: #5B4B51;
    text-align: left !important;
    font-weight: 600;
    padding-left: 15px;
}

.form-cuenta {
    text-align: left !important;
}

.modal-header-editar-cuenta {
    border-color: transparent;
    padding-bottom: 0rem;
    font-size: 18px;
    color: #5B4B51;
}

.modal-header-eliminar-cuenta {
    border-color: transparent;
    padding-bottom: 0rem;
    font-size: 18px;
    color: #5B4B51;
}

.modal-body-cuenta {
    padding: 1rem 0 1rem 0;
}

#modalNuevaCuenta>.modal-content {
    background-color: #4FD290;
    border-radius: 20px;
}

#modalEditarCuenta>.modal-content {
    background-color: #F3CA3E;
    border-radius: 20px;
}

#modalDeleteCuenta>.modal-content {
    background-color: #FF5F58;
    border-radius: 20px;
}

#modalDeleteCuenta>.modal-content>.modal-body {
    box-sizing: border-box;
    border-radius: 20px;
    background-color: #FFF;
    border: 3px solid #FF5F58;
}