#listadoFacturasSubidas tbody tr td {
    border-top-color: transparent;
    border-top-style: solid;
    border-top-width: 1px;
}

#modalConsultarFacturas>.modal-content {
    background-color: #FFC757;
    border-radius: 20px;
}

#modalDeleteFactura>.modal-content {
    background-color: #FF5F58;
    border-radius: 20px;
}

#modalDeleteFactura>.modal-content>.modal-body {
    box-sizing: border-box;
    border-radius: 20px;
    background-color: #FFF;
    border: 3px solid #FF5F58;
}

#modalSubirPDF>.modal-content {
    background-color: #1CBBC1;
    border-radius: 20px;
}

#modalSubirXML>.modal-content {
    background-color: #1CBBC1;
    border-radius: 20px;
}

.cfdi-tbody-tr-td-div {
    padding: .25rem .25rem;
}

.div-facturas-subidas {
    box-sizing: border-box;
    background-color: rgba(255, 255, 255, 0);
    border: 2px solid #5B4B51;
}

.font-col-cfdi {
    color: #000;
    font-weight: bolder;
}

.legend-consulta-facturas {
    font-size: 18px;
    color: #5B4B51;
    text-align: left !important;
    font-weight: 600;
    padding-left: 15px;
}

.modal-header-consulta-facturas {
    border-color: transparent;
    padding-bottom: 0rem;
    font-size: 18px;
    color: #5B4B51;
}

.modal-header-eliminar-factura {
    border-color: transparent;
    padding-bottom: 0rem;
    font-size: 18px;
    color: #5B4B51;
}

.modal-header-subir-archivo {
    border-color: transparent;
    padding-bottom: 0rem;
    font-size: 18px;
    color: #5B4B51;
}