@media(min-width: 320px) {
    #cardReqPendientes {
        border: 0rem;
        border-color: transparent;
    }
}

#reqPendientes thead {
    display: flex;
    justify-content: flex-start;
}

#reqPendientes tbody {
    display: flex;
    justify-content: space-between;
}

#reqPendientes tbody tr {
    display: grid;
    margin: 1rem;
    border-radius: 20px;
    box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.83);
    min-width: 240px;
}

#reqPendientes tbody tr td {
    border-top-color: transparent;
    border-top-style: solid;
    border-top-width: 1px;
    padding: 0%;
}

.cardBodyReqPendientes {
    padding: 0%;
}

.fieldsetReqPendientes {
    margin: 0;
    padding-top: 10px;
    border-radius: 4px;
}

.font-title-req-pendientes {
    padding: 0%;
    display: flex;
    justify-content: center;
    color: #fff;
    font-weight: bolder;
    font-size: 20px;
    border-radius: 20px 20px 0 0;
    background-color: #FD9558;
    width: 100%;
    height: 100%;
}

.header-class-pendientes {
    border-color: #FD9558;
    border-top-style: solid;
    border-bottom-style: solid;
}