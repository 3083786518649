/* Sí */

@media(min-width: 320px) {
  #cardPagoMultiple {
    border: 0rem;
    border-color: transparent;
  }
}

#pagosAnteriores tbody tr td {
  border-top-color: transparent;
  border-top-style: solid;
  border-top-width: 1px;
}

#modalConsultarSaldos>.modal-content {
  background-color: #FFC757;
  border-radius: 20px;
}

#modalSubirPago>.modal-content {
  background-color: #4FD290;
  border-radius: 20px;
}

#modalEditarPago>.modal-content {
  background-color: #FD9558;
  border-radius: 20px;
}

#modalNotificarPago>.modal-content {
  background-color: #FFC757;
  border-radius: 20px;
}

#modalNotificarPago>.modal-content>.modal-body {
  box-sizing: border-box;
  border-radius: 20px;
  background-color: #FFF;
  border: 3px solid #FFC757;
}

#selectCuenta tbody tr td {
  border-top-color: transparent;
  border-top-style: solid;
  border-top-width: 1px;
}

.cardPagoMultiple {
  padding: 0;
}

.div-pagos-anteriores {
  box-sizing: border-box;
  background-color: rgba(255, 255, 255, 0);
  border: 2px solid #5B4B51;
}

.div-select-cuenta {
  box-sizing: border-box;
  background-color: rgba(255, 255, 255, 0);
  border: 2px solid #5B4B51;
}

.font-col-pago {
  color: #000;
  font-weight: bolder;
}

.legend-consultar-pagos {
  font-size: 18px;
  color: #5B4B51;
  text-align: left !important;
  font-weight: 600;
  padding-left: 15px;
}

.legend-subir-pago {
  font-size: 18px;
  color: #5B4B51;
  text-align: left !important;
  font-weight: 600;
  padding-left: 15px;
}

.legend-editar-pago {
  font-size: 18px;
  color: #5B4B51;
  text-align: left !important;
  font-weight: 600;
  padding-left: 15px;
}

.modal-header-consultar-pagos {
  border-color: transparent;
  padding-bottom: 0rem;
  font-size: 18px;
  color: #5B4B51;
}

.modal-header-subir-pago {
  border-color: transparent;
  padding-bottom: 0rem;
  font-size: 18px;
  color: #5B4B51;
}

.modal-header-editar-pago {
  border-color: transparent;
  padding-bottom: 0rem;
  font-size: 18px;
  color: #5B4B51;
}

.modal-header-notificar-pago {
  border-color: transparent;
  padding-bottom: 0rem;
  font-size: 18px;
  color: #5B4B51;
}

.pago-tbody-tr-td-div {
  padding: .25rem .25rem;
}

.header-class-pago {
  border-color: #4FD290;
  border-top-style: solid;
  border-bottom-style: solid;
}

.header-class-pago-pendientes {
  border-color: #FD9558;
  border-top-style: solid;
  border-bottom-style: solid;
}

/* Sí */

@media(min-width: 1200px) {
  #pagosContent {
    margin-top: 0rem;
    margin-bottom: 2rem;
    margin-left: 2rem;
    margin-right: 2rem;
  }
  #cardPagos {
    margin-top: 0rem;
    margin-bottom: 2rem;
    border-color: transparent;
  }
}

@media(min-width: 320px) {
  #pagosContent {
    border: 0rem;
    border-color: transparent;
  }
  #cardPagos {
    border: 0rem;
  }
}

.cardBodyPagos {
  padding: 0;
}

.fieldsetPagos {
  margin: 0;
  padding: 10px 10px 0px 10px;
  position: relative;
  border-radius: 4px;
  padding-left: 10px!important;
}

.formRowButtons {
  justify-content: center;
}

/** Inicio Acciones (botones)*/

.buttonAcciones {
  background-color: #ffffff;
}

.buttonConsultar {
  background-color: #ffffff;
  border-color: #ffffff;
}

.buttonConsultar:hover {
  background-color: #e08bb0;
  border-color: #e08bb0;
  cursor: pointer;
  opacity: 1;
  visibility: visible;
}

.iconConsultar {
  color: #eeaeca;
}

.iconConsultar:hover {
  color: #ffffff;
  cursor: pointer;
  opacity: 1;
  visibility: visible;
}

.buttonConfirmar {
  background-color: #ffffff;
  border-color: #ffffff;
}

.buttonConfirmar:hover {
  background-color: #66c0ff;
  border-color: #66c0ff;
  cursor: pointer;
  opacity: 1;
  visibility: visible;
}

.iconConfirmar {
  color: #96caee;
}

.iconConfirmar:hover {
  color: #ffffff;
  cursor: pointer;
  opacity: 1;
  visibility: visible;
}

.buttonPagar {
  background-color: #ffffff;
  border-color: #ffffff;
}

.buttonPagar:hover {
  background-color: #2aee97;
  border-color: #2aee97;
  cursor: pointer;
  opacity: 1;
  visibility: visible;
}

.iconPagar {
  color: #6ee3af;
}

.iconPagar:hover {
  color: #ffffff;
  cursor: pointer;
  opacity: 1;
  visibility: visible;
}

.buttonCambiarFecha {
  background-color: #ffffff;
  border-color: #ffffff;
}

.buttonCambiarFecha:hover {
  background-color: #c378eb;
  border-color: #c378eb;
  cursor: pointer;
  opacity: 1;
  visibility: visible;
}

.iconCambiarFecha {
  color: #cf9ee9;
}

.iconCambiarFecha:hover {
  color: #ffffff;
  cursor: pointer;
  opacity: 1;
  visibility: visible;
}

/** Fin Acciones (botones)*/

.buttonPagoMultiple {
  background: #8de2b8;
  border-color: #8de2b8;
  color: #fff;
}

.buttonPagoMultiple:hover {
  background-color: #6ee3af;
  border-color: #6ee3af;
  color: #fff;
  cursor: pointer;
}

.buttonGenerarPago {
  background: #8de2b8;
  border-color: #8de2b8;
  color: #fff;
}

.buttonGenerarPago:hover {
  background-color: #6ee3af;
  border-color: #6ee3af;
  color: #fff;
  cursor: pointer;
}

/** Inicio tab */

.tabPagos {
  border-bottom: 1px solid #8de2b8;
  border-color: #8de2b8, #8de2b8;
}

/** Fin tab */

/**
  colores login:
  lila: #d4c3fc
  lila-azul: #b5c4fc
  azul: #99c4fc
  */