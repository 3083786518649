@media(min-width:1200px) {
    #formSoporte {
        margin-top: 0rem;
        margin-bottom: 2rem;
        margin-left: 2rem;
        margin-right: 2rem;
    }
}

.form-body-soporte {
    padding: 1rem 0 1rem 0;
    margin: 0;
    padding-top: 10px;
    border-radius: 4px;
}

.button-cancelar {
    color: #FFF;
    font-size: 13px;
    font-weight: 700;
    line-height: 20px;
    height: 25px;
    width: 110px;
    border-radius: 10px 10px 10px 10px;
    background-color: #F3CA3E;
    border-color: transparent;
}

.robotCaptcha {
    align-content: center;
}

.labelSoporte {
    display: flex;
    font-weight: bolder;
}

/* LISTADO Y CONSULTA */

.modal-body-solicitud {
    padding: 1rem 0 1rem 0;
}

.solicitud-tbody-tr-td-div {
    padding: .25rem .5rem;
}

.font-col-solicitud {
    color: #1CBBC1;
    font-weight: bolder;
}

.font-title-solicitudes {
    padding: 0%;
    display: flex;
    justify-content: center;
    color: #fff;
    font-weight: bolder;
    font-size: 20px;
    border-radius: 20px 20px 0 0;
    background-color: #1CBBC1;
    width: 100%;
    height: 100%;
}

#solicitudes thead {
    display: flex;
    justify-content: flex-start;
}

#solicitudes tbody {
    display: flex;
    justify-content: space-between;
}

#solicitudes tbody tr {
    display: grid;
    margin: 1rem;
    border-radius: 20px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.83);
    min-width: 200px;
}

#solicitudes tbody tr td {
    border-top-color: transparent;
    border-top-style: solid;
    border-top-width: 1px;
    padding: 0%;
}

.header-class-soporte {
    border-color: #96caee;
    border-top-style: solid;
    border-bottom-style: solid;
}

.legend-consultar-solicitud {
    font-size: 18px;
    color: #5B4B51;
    text-align: left !important;
    font-weight: 600;
    padding-left: 15px;
}

.modal-header-consultar-solicitud {
    border-color: transparent;
    padding-bottom: 0rem;
    font-size: 18px;
    color: #5B4B51;
}

.modal-header-update-estatus {
    background-color: #4FD290;
    border-color: transparent;
    border-radius: 20px 20px 0 0;
    padding-bottom: 1rem;
    font-size: 18px;
    color: #5B4B51;
}

.legend-update-estatus {
    font-size: 20px;
    color: #5B4B51;
    text-align: left !important;
    font-weight: 600;
    padding-left: 15px;
}

.modal-body-update-estatus {
    background-color: #fff;
    color: #5B4B51;
    padding-bottom: 0rem;
}

#modalConsultarSolicitud>.modal-content {
    background-color: #1CBBC1;
    border-radius: 20px;
}

#modalUpdateEstatus>.modal-content {
    background-color: #fff;
    border-radius: 20px;
}

/* LISTADO Y CONSULTA */